/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query Valencia {
    file(relativePath: { eq: "parking-lotnisko-valencia-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Valencia Manises Hiszpania",
  tagline: "Valencia Manises, Hiszpania",
  featuredImage: "/assets/parking-lotnisko-valencia-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> Carretera del Aeropuerto, s/n, 46940 Manises, Valencia,
        Hiszpania Kod: VLC
        <p>
          <b>Valencia Airport</b>, znane również jako <b>Manises Airport</b>,
          jest dziesiątym co do wielkości lotniskiem w Hiszpanii pod względem
          liczby pasażerów. Znajduje się ono 8 km na zachód od miasta Walencja,
          w miejscowości Manises. Dzięki temu, że obsługuje połączenia lotnicze
          z około 20 krajami europejskimi, jest ważnym punktem komunikacyjnym
          dla wielu podróżnych.
        </p>
      </p>
    }
  >
    <h2 id="parkingi-przy-lotnisku-valencia-w-hiszpanii">
      Parkingi przy lotnisku Valencia w Hiszpanii
    </h2>

    <h3 id="dlaczego-warto-wybrać-parking-przy-lotnisku-valencia">
      Dlaczego warto wybrać parking przy lotnisku Valencia?
    </h3>
    <ol>
      <li>
        <strong>Lokalizacja:</strong> Parkingi są zlokalizowane w bezpośrednim
        sąsiedztwie lotniska, co gwarantuje szybki dostęp do terminali.
      </li>
      <li>
        <strong>Bezpieczeństwo:</strong> Tereny parkingowe są monitorowane i
        strzeżone, co zapewnia bezpieczeństwo Twojemu samochodowi podczas Twojej
        nieobecności.
      </li>
      <li>
        <strong>Elastyczność:</strong> Możliwość rezerwacji miejsca parkingowego
        online pozwala na zaplanowanie podróży i gwarantuje dostępność miejsca.
      </li>
    </ol>
    <h3 id="jak-zarezerwować-miejsce-parkingowe">
      Jak zarezerwować miejsce parkingowe?
    </h3>
    <ol>
      <li>Wejdź na naszą stronę internetową.</li>
      <div className="searchCta">
        <a href="/#search" className="button">
          Pokaż listę parkingów
        </a>
      </div>
      <li>Wybierz datę i godzinę przyjazdu oraz wyjazdu.</li>
      <li>Wybierz preferowany rodzaj parkingu.</li>
      <li>Dokonaj płatności online.</li>
      <li>Otrzymasz potwierdzenie rezerwacji na swój adres e-mail.</li>
    </ol>
    <h3 id="podsumowanie">Podsumowanie</h3>
    <p>
      Jeśli planujesz podróż z lotniska Valencia w Hiszpanii, zaplanuj również
      parking dla swojego samochodu. Nasza strona oferuje różne opcje
      parkingowe, które spełnią Twoje oczekiwania i zapewnią bezpieczeństwo
      Twojemu pojazdowi podczas Twojej nieobecności. Rezerwuj online i ciesz się
      spokojem ducha podczas podróży!
    </p>
    <hr />
    <h2 id="dostępne-parkingi">Dostępne parkingi</h2>
    <h3 id="victoria-parking-walencja">Victoria Parking Walencja</h3>
    <ul>
      <li>
        <strong>Lokalizacja:</strong> Carretera del Aeropuerto, s/n, 46940
        Manises, Valencia
      </li>
      <li>
        <strong>Odległość od lotniska:</strong> 0.2 km (2 minuty)
      </li>
      <li>
        <strong>Udogodnienia:</strong>{" "}
        <ul>
          <li>Ubezpieczony</li>
          <li>Ogrodzony</li>
          <li>Darmowy transfer</li>
          <li>Mycie pojazdu</li>
          <li>Dozorowany</li>
          <li>Monitoring 24/7</li>
          <li>Obsługa 24/7</li>
        </ul>
      </li>
      <li>
        <strong>Cena:</strong> od 170 PLN za 7 dni
      </li>
    </ul>
    <p>
      <a href="#search">Rezerwuj teraz</a>
    </p>
    <h2 id="jak-dojechać-do-lotniska-valencia">
      Jak dojechać do lotniska Valencia?
    </h2>
    <p>
      Lotnisko Valencia Manises jest doskonale skomunikowane z centrum miasta.
      Możesz dojechać tam autobusem, metrem lub taksówką. Współrzędne GPS dla
      systemów nawigacji to: 39°29&#39;29&quot;N, 0°28&#39;25&quot;W.
    </p>
    <h2 id="wskazówki-dla-kierowców">Wskazówki dla kierowców</h2>
    <p>
      Jeśli planujesz podróż samochodem do lotniska Valencia, pamiętaj o
      sprawdzeniu dostępności miejsc parkingowych z wyprzedzeniem. W godzinach
      szczytu ruchu drogowego w Walencji może być wzmożony, dlatego warto
      wyjechać wcześniej, aby uniknąć opóźnień.
    </p>
    <h2 id="podsumowanie">Podsumowanie</h2>
    <p>
      Zaplanuj swoją podróż z wyprzedzeniem i zarezerwuj miejsce parkingowe w
      pobliżu lotniska Valencia. Dzięki temu Twoja podróż będzie bardziej
      komfortowa i bezstresowa. Skorzystaj z naszej platformy, aby znaleźć
      najlepsze oferty parkingowe w okolicy lotniska Valencia Manises.
    </p>
  </Homepage>
)

export default HomePage
